import React from "react"
import Layout from "../../components/layout/Layout"

export default () => {
  return (
    <Layout type="HMNF" title="404: Página no encontrada">
      <h2>PÁGINA NO ENCONTRADA</h2>
      <p>Texto para redirigir</p>
    </Layout>
  )
}
